import { Auth, Hub } from 'aws-amplify'
import { useSession } from 'next-auth/react'
import { useCallback, useEffect, useState } from 'react'

export const useAccessToken = () => {
  const [token, setToken] = useState<string | undefined>(undefined)

  // NOTE: useSessionでsessionが取得できる = SNSログイン
  const { data: session, status } = useSession()
  useEffect(() => {
    if (status === 'authenticated') {
      setToken(session?.access_token)
    } else {
      setToken(undefined)
    }
  }, [session, status])

  const fetchCognitoUser = useCallback(async () => {
    try {
      const session = await Auth.currentSession()
      const token = session.getAccessToken().getJwtToken()
      setToken(token)
    } catch {
      setToken(undefined)
    }
  }, [])

  useEffect(() => {
    fetchCognitoUser()

    const listener = async (data: any) => {
      const { event } = data.payload

      if (event === 'signIn' || event === 'signOut') {
        fetchCognitoUser()
      }
    }

    const cancel = Hub.listen('auth', listener)

    return () => {
      cancel()
    }
  }, [fetchCognitoUser])

  return token
}
