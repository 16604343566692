'use client'

import { useQuery } from '@apollo/client'
import { isAxiosError } from 'axios'
import { createContext, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import {
  GetCurrentUserWithEmailDocument,
  GetCurrentUserWithEmailQuery,
} from '~/__generated__/graphql'
import { useAddLocaleParams } from '~/features/i18n/hooks/useAddLocaleParams'
import { useAxios } from '~/libs/axios'
import * as GA from '~/libs/ga'
import { useAccessToken } from './useAccessToken'

export type LoginState =
  | 'notSignedIn'
  | 'signedIn'
  | 'noProfile'
  | 'conflict'
  | 'preventNewSocialSignedIn'

type Provider = 'Cognito' | 'Google' | 'SignInWithApple' | 'Twitter'

type LoginInfo = {
  status: LoginState
  provider?: Provider
  email?: string
}

const LoginInfoContext = createContext<LoginInfo>({
  status: 'notSignedIn',
  provider: undefined,
  email: undefined,
})

export const LoginInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const accessToken = useAccessToken()
  const [loginInfo, setLoginInfo] = useState<LoginInfo>({
    status: 'notSignedIn',
  })

  const axios = useAxios()
  const localeParams = useAddLocaleParams()
  const { data: userData, error: userError } = useQuery<GetCurrentUserWithEmailQuery>(
    GetCurrentUserWithEmailDocument,
  )
  const { error: profileError, isLoading } = useSWR(
    accessToken ? `/v2/user/edit?${localeParams}` : null,
    (args) => axios.get(args).then((res) => res.data),
  )

  // ユーザーのログイン状態を更新する
  useEffect(() => {
    if (isLoading) return

    if (profileError && isAxiosError(profileError) && profileError.response?.status === 406) {
      setLoginInfo({ status: 'preventNewSocialSignedIn' })
      return
    }

    if (userError) {
      const graphQLError = userError.graphQLErrors[0]
      const reason = graphQLError.extensions.reason
      switch (reason) {
        case 'REQUIRE_PROFILE':
          setLoginInfo({ status: 'noProfile', email: userData?.currentUser?.email || '' })
          return
        case 'PROVIDER_MISMATCH':
          setLoginInfo({
            status: 'conflict',
            email: graphQLError.extensions.email as string,
            provider: graphQLError.extensions.provider as Provider,
          })
          return
        default:
          setLoginInfo({ status: 'notSignedIn' })
          GA.setUserType('notLogin')
          break
      }
    } else if (userData?.currentUser != undefined) {
      setLoginInfo({ status: 'signedIn', email: userData.currentUser.email || '' })
    } else if (accessToken === undefined) {
      setLoginInfo({ status: 'notSignedIn' })
      GA.setUserType('notLogin')
    }
  }, [isLoading, profileError, accessToken, userError, userData])

  return <LoginInfoContext.Provider value={loginInfo}>{children}</LoginInfoContext.Provider>
}

export const useLoginInfo = () => {
  return useContext(LoginInfoContext)
}
