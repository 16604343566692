'use client'

import { useRouter } from "next/navigation";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { userLoginState } from '~/recoil/auth';

export const useBeforeLoginSessionStorage = () => {
  const { push } = useRouter();
  const loginState = useRecoilValue(userLoginState);

  const setBeforeLoginPage = useCallback(() => {
    const BeforeLoginPageUrl = window.location.pathname;
    if (loginState === 'notSignedIn') {
      sessionStorage.setItem('before-login-page', BeforeLoginPageUrl);
    }

  }, [loginState]);

  const pushRedirectToBeforeLoginPage = useCallback(() => {
    const beforeLoginPage = sessionStorage.getItem('before-login-page');
    if (!beforeLoginPage) return push('/');

    push(beforeLoginPage as __next_route_internal_types__.RouteImpl<string>);
  }, [push]);

  return { setBeforeLoginPage, pushRedirectToBeforeLoginPage };
};
